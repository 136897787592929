var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('loanWriteOff.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('loanWriteOff.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('addNew')))]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('loanWriteOff.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"branch",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.branch ? row.branch.branch_name_en : '')+" ")]}},{key:"requested_by",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.requested_by.employee ? row.requested_by.employee.employee_name_en : row.requested_by.user_name)+" ")]}},{key:"customer",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer_name_en)+" | "+_vm._s(row.customer_name_kh)+" ")]}},{key:"write_off_amount",fn:function(ref){
        var row = ref.row;
return [_c('strong',{staticClass:"tw-text-yellow-500"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.write_off_amount, row.currency )))])]}},{key:"recovery_amount",fn:function(ref){
        var row = ref.row;
return [_c('strong',{staticClass:"tw-text-green-500"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.recovery_amount, row.currency )))])]}},{key:"co",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.co_name_en)+" | "+_vm._s(row.co_name_kh)+" ")]}},{key:"interest",fn:function(ref){
        var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.interest, row.currency)))])]}},{key:"monthly_fee",fn:function(ref){
        var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.monthly_fee, row.currency)))])]}},{key:"approve_status",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"expand-value tw-ml-2",class:_vm.statusStyle(row.approve_status)},[_vm._v(_vm._s(row.approve_status ? row.approve_status.approve_status : ''))])]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1)]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Drawer',{attrs:{"title":_vm.$t('filter'),"closable":false,"value":_vm.visible,"width":"300px"},on:{"on-close":function () { return (_vm.visible = false); }}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('branchName')))]),_c('ts-branch-filter',{on:{"filter":function (value) { return (_vm.branch_id = value); }}})],1)]),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('loanWriteOff.pageTitle'),"width":"700"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
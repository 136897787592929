<template>
    <div>
        <ts-page-title
            :title="$t('loanWriteOff.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanWriteOff.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanWriteOff.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="requested_by">
                    {{
                        row.requested_by.employee
                            ? row.requested_by.employee.employee_name_en
                            : row.requested_by.user_name
                    }}
                </template>
                <template slot-scope="{ row }" slot="customer">
                    {{ row.customer_name_en }} | {{ row.customer_name_kh }}
                </template>
                <template slot-scope="{ row }" slot="write_off_amount">
                    <strong class="tw-text-yellow-500">{{
                        formatCurrencyWithCode(
                            row.write_off_amount,
                            row.currency
                        )
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="recovery_amount">
                    <strong class="tw-text-green-500">{{
                        formatCurrencyWithCode(
                            row.recovery_amount,
                            row.currency
                        )
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="co">
                    {{ row.co_name_en }} | {{ row.co_name_kh }}
                </template>
                <template slot-scope="{ row }" slot="interest">
                    <strong>{{
                        formatCurrencyWithCode(row.interest, row.currency)
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="monthly_fee">
                    <strong>{{
                        formatCurrencyWithCode(row.monthly_fee, row.currency)
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="approve_status">
                    <span
                        class="expand-value tw-ml-2"
                        :class="statusStyle(row.approve_status)"
                        >{{
                            row.approve_status
                                ? row.approve_status.approve_status
                                : ''
                        }}</span
                    >
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Drawer
                :title="$t('filter')"
                :closable="false"
                :value="visible"
                @on-close="() => (visible = false)"
                width="300px"
            >
                <div class="mb-3">
                    <label class="form-col-label control-label">{{
                        $t('branchName')
                    }}</label>
                    <ts-branch-filter @filter="value => (branch_id = value)" />
                </div>
            </Drawer>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('loanWriteOff.pageTitle')"
                width="700"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'loanWriteOffIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            visible: false,
            branch_id: [],
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('creditOperation/loanWriteOff', [
            'resources',
            'pagination'
        ]),
        ...mapGetters(['formatCurrencyWithCode']),
        search: {
            get () {
                return this.$store.state.creditOperation.loanWriteOff.search
            },
            set (newValue) {
                this.$store.commit(
                    'creditOperation/loanWriteOff/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'creditOperation/loanWriteOff/RESET_CURRENT_PAGE'
                )
            }
        },
        statusStyle: () => status => {
            let badge = ''

            switch (status.approve_status_id) {
                case 0:
                    badge = 'badge bg-info'
                    break
                case 1:
                    badge = 'badge bg-primary'
                    break
                case 2:
                    badge = 'badge bg-success'
                    break
                case 3:
                    badge = 'badge bg-default'
                    break
            }

            return badge
        },
        columns () {
            return [
                {
                    title: this.$t('loanWriteOff.branch'),
                    slot: 'branch',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.requestedDate'),
                    key: 'requested_date',
                    sortable: true,
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.requestedBy'),
                    slot: 'requested_by',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.loanNumber'),
                    key: 'loan_number',
                    sortable: true,
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.customerName'),
                    slot: 'customer',
                    sortable: true,
                    minWidth: 260
                },
                {
                    title: this.$t('loanWriteOff.writeOffType'),
                    key: 'write_off_type',
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t('loanWriteOff.writeOffAmount'),
                    slot: 'write_off_amount',
                    sortable: true,
                    align: 'right',
                    minWidth: 200
                },
                {
                    title: this.$t('loanWriteOff.recoveryAmount'),
                    slot: 'recovery_amount',
                    sortable: true,
                    align: 'right',
                    minWidth: 200
                },
                {
                    title: this.$t('loanWriteOff.co'),
                    slot: 'co',
                    sortable: true,
                    minWidth: 250
                },
                {
                    title: this.$t('loanWriteOff.reason'),
                    key: 'write_off_reason',
                    minWidth: 250
                },
                {
                    title: this.$t('loanWriteOff.interest'),
                    slot: 'interest',
                    sortable: true,
                    align: 'right',
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.monthlyFee'),
                    slot: 'monthly_fee',
                    sortable: true,
                    align: 'right',
                    minWidth: 150
                },
                {
                    title: this.$t('loanWriteOff.approveStatus'),
                    slot: 'approve_status',
                    minWidth: 150,
                    align: 'center',
                    fixed: 'right',
                    filters: [
                        {
                            value: 0,
                            label: 'Pending'
                        },
                        {
                            value: 1,
                            label: 'Partially approved'
                        },
                        {
                            value: 2,
                            label: 'Approved'
                        },
                        {
                            value: 3,
                            label: 'Rejected'
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                approve_status: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/loanWriteOff/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onEdit (record) {
            this.$store.commit(
                'creditOperation/loanWriteOff/SET_EDIT_DATA',
                record
            )
            this.showForm = true
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('creditOperation/loanWriteOff/SET_EDIT_DATA', {})
        },
        addNew () {
            this.$store.commit('creditOperation/loanWriteOff/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN WRITE-OFF',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search, branch_id: this.branch_id })
        }, 500),
        branch_id: debounce(function (value) {
            this.fetchData({ branch_id: value })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/loanWriteOff/RESET_STATE')
        next()
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // }
}
</script>
